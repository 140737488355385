function BurgerIcon(props: { isToggled: boolean; onClick: () => void }) {
  const lineClass =
    'h-[4px] bg-[#1E1E1E] absolute w-[80%] origin-center transition-all duration-300 ease-in-out';

  return (
    <div
      onClick={props.onClick}
      className={`w-12 h-12 relative cursor-pointer ${
        props.isToggled ? 'toggled' : ''
      }`}
    >
      <div
        className={`${lineClass} ${
          props.isToggled ? 'top-[25%]' : 'top-[15%]'
        } line1`}
      ></div>
      <div className={`${lineClass} top-[40%] line2`}></div>
      <div
        className={`${lineClass} ${
          props.isToggled ? 'top-[50%]' : 'top-[65%]'
        } line3`}
      ></div>
    </div>
  );
}

export default BurgerIcon;
