export default function SocialIcons() {
  return (
    <div className="flex flex-row gap-4 items-center">
      <svg
        onClick={() =>
          window.open('https://www.facebook.com/KFDpolska', '_blank')
        }
        className="cursor-pointer"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_158_50)">
          <path
            d="M16 8C16 11.9931 13.0744 15.3028 9.25 15.9028V10.3125H11.1141L11.4688 8H9.25V6.49937C9.25 5.86656 9.56 5.25 10.5538 5.25H11.5625V3.28125C11.5625 3.28125 10.6469 3.125 9.77156 3.125C7.94438 3.125 6.75 4.2325 6.75 6.2375V8H4.71875V10.3125H6.75V15.9028C2.92563 15.3028 0 11.9931 0 8C0 3.58187 3.58187 0 8 0C12.4181 0 16 3.58187 16 8Z"
            fill="#363636"
          />
          <path
            d="M11.1141 10.3125L11.4688 8H9.25V6.49934C9.25 5.86669 9.55994 5.25 10.5537 5.25H11.5625V3.28125C11.5625 3.28125 10.647 3.125 9.77172 3.125C7.94434 3.125 6.75 4.2325 6.75 6.2375V8H4.71875V10.3125H6.75V15.9028C7.15731 15.9667 7.57475 16 8 16C8.42525 16 8.84269 15.9667 9.25 15.9028V10.3125H11.1141Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_158_50">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        onClick={() =>
          window.open(
            'https://www.instagram.com/kfd_sp.zoo?fbclid=IwY2xjawEhmrRleHRuA2FlbQIxMAABHbMsKSkd10Jw-PxS6qSihfCQtw3oe-KKCFFIScTgoMOrdhU77pMI1v8qMA_aem_-MplzcFkJZFYZdXx-r_ZnA',
            '_blank',
          )
        }
        className="cursor-pointer"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_158_54)">
          <circle cx="8" cy="8" r="7" fill="#595959" />
          <path
            d="M9.53125 8C9.53125 8.8457 8.8457 9.53125 8 9.53125C7.1543 9.53125 6.46875 8.8457 6.46875 8C6.46875 7.1543 7.1543 6.46875 8 6.46875C8.8457 6.46875 9.53125 7.1543 9.53125 8Z"
            fill="#363636"
          />
          <path
            d="M11.5811 5.29077C11.5074 5.09131 11.39 4.91077 11.2374 4.76257C11.0892 4.60999 10.9088 4.49255 10.7092 4.41895C10.5474 4.35608 10.3042 4.28125 9.85632 4.26086C9.37183 4.23877 9.22656 4.23401 8 4.23401C6.77332 4.23401 6.62805 4.23865 6.14368 4.26074C5.6958 4.28125 5.45251 4.35608 5.29077 4.41895C5.09119 4.49255 4.91064 4.60999 4.76257 4.76257C4.60999 4.91077 4.49255 5.09119 4.41882 5.29077C4.35596 5.45264 4.28113 5.69592 4.26074 6.1438C4.23865 6.62817 4.23389 6.77344 4.23389 8.00012C4.23389 9.22669 4.23865 9.37195 4.26074 9.85645C4.28113 10.3043 4.35596 10.5475 4.41882 10.7094C4.49255 10.9089 4.60986 11.0894 4.76245 11.2375C4.91064 11.3901 5.09106 11.5076 5.29065 11.5812C5.45251 11.6442 5.6958 11.719 6.14368 11.7394C6.62805 11.7615 6.77319 11.7661 7.99988 11.7661C9.22669 11.7661 9.37195 11.7615 9.8562 11.7394C10.3041 11.719 10.5474 11.6442 10.7092 11.5812C11.1099 11.4266 11.4265 11.11 11.5811 10.7094C11.6439 10.5475 11.7188 10.3043 11.7393 9.85645C11.7614 9.37195 11.766 9.22669 11.766 8.00012C11.766 6.77344 11.7614 6.62817 11.7393 6.1438C11.7189 5.69592 11.644 5.45264 11.5811 5.29077ZM8 10.3589C6.69714 10.3589 5.64099 9.30286 5.64099 8C5.64099 6.69714 6.69714 5.64111 8 5.64111C9.30273 5.64111 10.3589 6.69714 10.3589 8C10.3589 9.30286 9.30273 10.3589 8 10.3589ZM10.4521 6.09912C10.1477 6.09912 9.90088 5.8523 9.90088 5.54785C9.90088 5.24341 10.1477 4.99658 10.4521 4.99658C10.7566 4.99658 11.0034 5.24341 11.0034 5.54785C11.0033 5.8523 10.7566 6.09912 10.4521 6.09912Z"
            fill="white"
          />
          <path
            d="M8 0C3.5824 0 0 3.5824 0 8C0 12.4176 3.5824 16 8 16C12.4176 16 16 12.4176 16 8C16 3.5824 12.4176 0 8 0ZM12.566 9.89392C12.5438 10.3829 12.4661 10.7168 12.3525 11.009C12.1139 11.6261 11.6261 12.1139 11.009 12.3525C10.7169 12.4661 10.3829 12.5437 9.89404 12.566C9.40417 12.5884 9.24768 12.5938 8.00012 12.5938C6.75244 12.5938 6.59607 12.5884 6.10608 12.566C5.61719 12.5437 5.2832 12.4661 4.99109 12.3525C4.68445 12.2372 4.40686 12.0564 4.17737 11.8226C3.94373 11.5933 3.76294 11.3156 3.64758 11.009C3.53406 10.7169 3.4563 10.3829 3.43408 9.89404C3.4115 9.40405 3.40625 9.24756 3.40625 8C3.40625 6.75244 3.4115 6.59595 3.43396 6.10608C3.45618 5.61707 3.53381 5.2832 3.64734 4.99097C3.7627 4.68445 3.9436 4.40674 4.17737 4.17737C4.40674 3.9436 4.68445 3.76282 4.99097 3.64746C5.2832 3.53394 5.61707 3.4563 6.10608 3.43396C6.59595 3.41162 6.75244 3.40625 8 3.40625C9.24756 3.40625 9.40405 3.41162 9.89392 3.43408C10.3829 3.4563 10.7168 3.53394 11.009 3.64734C11.3156 3.7627 11.5933 3.9436 11.8228 4.17737C12.0564 4.40686 12.2373 4.68445 12.3525 4.99097C12.4662 5.2832 12.5438 5.61707 12.5662 6.10608C12.5885 6.59595 12.5938 6.75244 12.5938 8C12.5938 9.24756 12.5885 9.40405 12.566 9.89392Z"
            fill="#363636"
          />
        </g>
        <defs>
          <clipPath id="clip0_158_54">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
