import { defaultStyles } from '@/utils/defaultStyles';
import { useState } from 'react';
import BurgerIcon from './BurgerIcon';
import SocialIcons from './SocialIcons';

export const routes = [
  {
    title: 'Strona główna',
    route: '/',
  },
  {
    title: 'O nas',
    id: 'about',
  },
  {
    title: 'Kontakt',
    route: '/contact',
  },
  {
    title: 'Blog',
    route: '/blog',
  },
];

function Navbar(props: { withoutHero?: boolean; withBorder?: boolean }) {
  const [isBurgerToggled, setIsBurgerToggled] = useState(false);

  return (
    <div className="h-[92px] fixed top-0 z-50 w-screen flex flex-col">
      <div className="py-2 px-2 bg-[#1E1E1E] w-full flex flex-row gap-4">
        <div className="flex flex-row gap-2">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.4799 12.9466C14.3666 13.1866 14.2199 13.4133 14.0266 13.6266C13.6999 13.9866 13.3399 14.2466 12.9333 14.4133C12.5333 14.5799 12.0999 14.6666 11.6333 14.6666C10.9533 14.6666 10.2266 14.5066 9.45992 14.1799C8.69325 13.8533 7.92658 13.4133 7.16658 12.8599C6.39992 12.2999 5.67325 11.6799 4.97992 10.9933C4.29325 10.2999 3.67325 9.57325 3.11992 8.81325C2.57325 8.05325 2.13325 7.29325 1.81325 6.53992C1.49325 5.77992 1.33325 5.05325 1.33325 4.35992C1.33325 3.90659 1.41325 3.47325 1.57325 3.07325C1.73325 2.66659 1.98659 2.29325 2.33992 1.95992C2.76659 1.53992 3.23325 1.33325 3.72659 1.33325C3.91325 1.33325 4.09992 1.37325 4.26659 1.45325C4.43992 1.53325 4.59325 1.65325 4.71325 1.82659L6.25992 4.00659C6.37992 4.17325 6.46658 4.32659 6.52658 4.47325C6.58658 4.61325 6.61992 4.75325 6.61992 4.87992C6.61992 5.03992 6.57325 5.19992 6.47992 5.35325C6.39325 5.50658 6.26658 5.66658 6.10658 5.82658L5.59992 6.35325C5.52658 6.42658 5.49325 6.51325 5.49325 6.61992C5.49325 6.67325 5.49992 6.71992 5.51325 6.77325C5.53325 6.82658 5.55325 6.86658 5.56659 6.90658C5.68659 7.12658 5.89325 7.41325 6.18658 7.75992C6.48658 8.10659 6.80658 8.45992 7.15325 8.81325C7.51325 9.16658 7.85992 9.49325 8.21325 9.79325C8.55992 10.0866 8.84658 10.2866 9.07325 10.4066C9.10658 10.4199 9.14658 10.4399 9.19325 10.4599C9.24658 10.4799 9.29992 10.4866 9.35992 10.4866C9.47325 10.4866 9.55992 10.4466 9.63325 10.3733L10.1399 9.87325C10.3066 9.70659 10.4666 9.57992 10.6199 9.49992C10.7733 9.40658 10.9266 9.35992 11.0933 9.35992C11.2199 9.35992 11.3533 9.38658 11.4999 9.44658C11.6466 9.50658 11.7999 9.59325 11.9666 9.70659L14.1733 11.2733C14.3466 11.3933 14.4666 11.5333 14.5399 11.6999C14.6066 11.8666 14.6466 12.0333 14.6466 12.2199C14.6466 12.4599 14.5933 12.7066 14.4799 12.9466Z"
              stroke="white"
              stroke-width="1.5"
            />
          </svg>
          <a
            className="text-[12px] text-white cursor-pointer"
            href="tel:+48507005710"
          >
            +48 507 005 710
          </a>
        </div>
        <div className="flex flex-row gap-2">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.66665 13.6666C2.66665 13.6666 1.33331 12.6666 1.33331 10.3333V5.66659C1.33331 3.33325 2.66665 2.33325 4.66665 2.33325H11.3333C13.3333 2.33325 14.6666 3.33325 14.6666 5.66659V10.3333C14.6666 12.6666 13.3333 13.6666 11.3333 13.6666H4.66665Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.3334 6L9.24668 7.66667C8.56002 8.21333 7.43335 8.21333 6.74668 7.66667L4.66669 6"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <a
            className="text-[12px] text-white cursor-pointer"
            href="mailto:info@kfd.eu"
          >
            info@kfd.eu
          </a>
        </div>
        <SocialIcons />
      </div>
      <div
        className={`${defaultStyles.padding} bg-white border-[0.5px] border-solid border-[#A7A7A7] border-t-0 border-l-0 border-r-0`}
      >
        <nav
          className={`bg-white flex flex-row lg:justify-evenly justify-between items-center py-4`}
        >
          <div className="hidden lg:flex flex-row gap-4 items-center">
            {routes.slice(0, 2).map((item, index) => (
              <button
                key={index}
                className={`w-[150px] text-[16px] font-bold bg-transparent btn btn-ghost border border-solid border-transparent hover:border-black rounded-[68px] transition-all duration-300 cursor-pointer ${props.withoutHero ? 'text-black' : 'text-white'}`}
                onClick={() => {
                  if (item.route) {
                    window.location.assign(item.route);
                  } else if (item.id) {
                    if (window.location.pathname != '/') {
                      window.location.assign('/');
                    }
                    document
                      .getElementById(item.id)!
                      .scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
          <img
            loading="lazy"
            src="/logo.png"
            alt="KFD logo"
            className="w-[130px] h-[70px]"
          />
          <div className="hidden lg:flex flex-row gap-4 items-center">
            {routes.slice(2, 4).map((item, index) => (
              <button
                key={index}
                className={`w-[150px] text-[16px] font-bold bg-transparent btn btn-ghost border border-solid border-transparent hover:border-black rounded-[68px] transition-all duration-300 cursor-pointer ${props.withoutHero ? 'text-black' : 'text-white'}`}
                onClick={() => {
                  if (item.route) {
                    window.location.assign(item.route);
                  } else if (item.id) {
                    if (window.location.pathname != '/') {
                      window.location.assign('/');
                    }
                    document
                      .getElementById(item.id)!
                      .scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className="flex lg:hidden flex-col justify-center items-center">
            <BurgerIcon
              isToggled={isBurgerToggled}
              onClick={() => setIsBurgerToggled(!isBurgerToggled)}
            />
          </div>
        </nav>
        {isBurgerToggled && (
          <div className="w-full bg-white pb-8">
            <div className="flex flex-col gap-4 items-start">
              {routes.map((item, index) => (
                <button
                  key={index}
                  className={`text-[16px] font-bold bg-transparent btn btn-ghost border border-solid border-transparent hover:border-black rounded-[68px] transition-all duration-300 cursor-pointer ${props.withoutHero ? 'text-black' : 'text-white'}`}
                  onClick={() => {
                    if (item.route) {
                      window.location.assign(item.route);
                    } else if (item.id) {
                      if (window.location.pathname != '/') {
                        window.location.assign('/');
                      }
                      document
                        .getElementById(item.id)!
                        .scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
